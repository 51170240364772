import React from 'react';
import "./Privacy_Policy.css";
import Footer from '../../component/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import Linebrake from '../../component/Linebrake/Linebrake';

function Privacy_Policy() {
    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Privacy Policy</title>
                <meta name="title" content="Ripper & Co. Southsea | Privacy Policy" />
                <meta name="description"
                    content="Unravel the secrets of our Privacy Policy as we protect your information with spine-chilling vigilance." />

                <meta property="og:title" content="Ripper & Co. Southsea | Privacy Policy" />
                <meta property="og:description"
                    content="Unravel the secrets of our Privacy Policy as we protect your information with spine-chilling vigilance." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Privacy Policy" />
                <meta property="twitter:description"
                    content="Unravel the secrets of our Privacy Policy as we protect your information with spine-chilling vigilance." />
            </Helmet>


            <header className='header privacy_policy_header'>
                <div className="container">
                    <div className="header_content">
                        <h2 className="heading_2">PRIVACY POLICY</h2>
                        <div className="mobile_line_break">
                            <Linebrake />
                        </div>
                        <h1 className="heading_1">
                            Unravel the secrets of our Privacy Policy as we protect your information with spine-chilling vigilance.
                        </h1>
                    </div>
                </div >
            </header>
            <div className="privecy_policy_content">
                <div className="container">
                    <p className="privacy_policy_paragraph">Last updated: October 23, 2023</p>
                    <p className="privacy_policy_paragraph">This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
                    <p className="privacy_policy_paragraph">We use Your data to provide and improve the Service. By using the Service, You agree to the collection and use of information per this Privacy Policy.</p>
                    <h1 className="privacy_policy_heading">Interpretation and Definitions</h1>
                    <h2 className="privacy_policy_heading">Interpretation</h2>
                    <p className="privacy_policy_paragraph">The words of which the initial letter is capitalised have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear singular or plural.</p>
                    <h2 className="privacy_policy_heading">Definitions</h2>
                    <p className="privacy_policy_paragraph">For this Privacy Policy:</p>
                    <ul>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;, or &quot;Our&quot; in this Agreement) refers to Hampshire Pub Co Ltd, The Red Lion, Southwick, Fareham, Hampshire, United Kingdom, PO17 6EF.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Cookies</strong> are small files placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that Website among its many uses.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Country</strong> refers to:  United Kingdom.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Device</strong> means any device that can access the Service, such as a computer, a mobile phone or a digital tablet.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Service</strong> refers to the Website.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analysing how the Service is used.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Website</strong> refers to Ripper & Co., accessible from <a href="https://ripperandco.co.uk" rel="external nofollow noopener" target="_blank">https://ripperandco.co.uk.</a></p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
                        </li>
                    </ul>
                    <h1 className="privacy_policy_heading">Collecting and Using Your Data</h1>
                    <h2 className="privacy_policy_heading">Types of Data Collected</h2>
                    <h3 className="privacy_policy_heading">Personal Data</h3>
                    <p className="privacy_policy_paragraph">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
                    <ul>
                        <li>
                            <p className="privacy_policy_paragraph">Email address</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph">First name and last name</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph">Usage Data</p>
                        </li>
                    </ul>
                    <h3 className="privacy_policy_heading">Usage Data</h3>
                    <p className="privacy_policy_paragraph">Usage Data is collected automatically when using the Service.</p>
                    <p className="privacy_policy_paragraph">When you use the Service, Usage Data is automatically collected.
                        Usage Data may include Your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>
                    <p className="privacy_policy_paragraph">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device's unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
                    <p className="privacy_policy_paragraph">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
                    <h3 className="privacy_policy_heading">Tracking Technologies and Cookies</h3>
                    <p className="privacy_policy_paragraph">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies are beacons, tags, and scripts to collect and track information and improve and analyse Our Service. The technologies We use may include:</p>
                    <ul>
                        <li className="privacy_policy_paragraph"><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a cookie is being sent. However, if You do not accept Cookies, You may be unable to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse cookies, our Service may use Cookies.</li>
                        <li className="privacy_policy_paragraph"><strong>Flash Cookies.</strong> Certain Service features may use locally stored objects (or Flash Cookies) to collect and store information about Your preferences or activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how You can delete Flash Cookies, please read "Where can I change the settings for disabling or deleting local shared objects?" available <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_" rel="external nofollow noopener" target="_blank">here.</a></li>
                        <li className="privacy_policy_paragraph"><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a specific section and verifying system and server integrity).
                        </li>
                    </ul>
                    <p className="privacy_policy_paragraph">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline. Session Cookies are deleted as soon as You close Your web browser.</p>
                    <p className="privacy_policy_paragraph">We use both Session and Persistent Cookies for the purposes set out below:</p>
                    <ul>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Necessary / Essential Cookies</strong></p>
                            <p className="privacy_policy_paragraph">Type: Session Cookies</p>
                            <p className="privacy_policy_paragraph">Administered by: Us</p>
                            <p className="privacy_policy_paragraph">Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                            <p className="privacy_policy_paragraph">Type: Persistent Cookies</p>
                            <p className="privacy_policy_paragraph">Administered by: Us</p>
                            <p className="privacy_policy_paragraph">Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>Functionality Cookies</strong></p>
                            <p className="privacy_policy_paragraph">Type: Persistent Cookies</p>
                            <p className="privacy_policy_paragraph">Administered by: Us</p>
                            <p className="privacy_policy_paragraph">Purpose: These Cookies identify if users have accepted cookies on the Website.</p>
                        </li>
                    </ul>
                    <h2 className="privacy_policy_heading">Use of Your Data</h2>
                    <p className="privacy_policy_paragraph">The Company may use Personal Data for the following purposes:</p>
                    <ul>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>To provide and maintain our Service</strong>, including monitoring the usage of our Service.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>To manage Your Account:</strong> to manage Your registration as a Service user. The Personal Data You provide can give You access to different Service functionalities as a registered user.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>To provide You</strong> with news, special offers and general information about other goods, services and events we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
                        </li>
                        <li>
                            <p className="privacy_policy_paragraph"><strong>For other purposes</strong>: We may also use Your information for data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving our Service, products, services, marketing, and your experience.</p>
                        </li>
                    </ul>
                    <p className="privacy_policy_paragraph">We may share Your personal information in the following situations:</p>
                    <ul>
                        <li className="privacy_policy_paragraph"><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyse the use of our Service to contact You.</li>
                        <li className="privacy_policy_paragraph"><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
                        <li className="privacy_policy_paragraph"><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honour this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or are under common control with Us.</li>
                        <li className="privacy_policy_paragraph"><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
                        <li className="privacy_policy_paragraph"><strong>With other users:</strong> When you share personal information or otherwise interact in public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
                        <li className="privacy_policy_paragraph"><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
                    </ul>
                    <h2 className="privacy_policy_heading">Retention of Your Data</h2>
                    <p className="privacy_policy_paragraph">The Company will retain Your Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                        The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or improve the functionality of Our Service.</p>
                    <h2 className="privacy_policy_heading">Transfer of Your Data</h2>
                    <p className="privacy_policy_paragraph">In the UK, your data, including Personal Data, is processed at the Company's operational offices and any other locations where the parties involved are situated. Your information may be transferred to and stored on computers outside the United Kingdom, your local jurisdiction, where data protection laws may vary from those in your jurisdiction.
                        Your consent to this Privacy Policy, followed by Your submission of such information, represents Your agreement to that transfer.
                        The Company will take all necessary steps to ensure that Your data is treated securely per this Privacy Policy. No transfer of Your Data will occur to an organisation or a country unless there are adequate controls, including the security of Your data and other personal information.</p>
                    <p className="privacy_policy_paragraph">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
                    <p className="privacy_policy_paragraph">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                    <h2 className="privacy_policy_heading">Disclosure of Your Data</h2>
                    <h3 className="privacy_policy_heading">Business Transactions</h3>
                    <p className="privacy_policy_paragraph">Your Data may be transferred if the Company is involved in a merger, acquisition or asset sale. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
                    </p>
                    <h3 className="privacy_policy_heading">Law enforcement</h3>
                    <p className="privacy_policy_paragraph">Under certain circumstances, the Company may be required to disclose Your Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                    </p>
                    <h3 className="privacy_policy_heading">Other legal requirements</h3>
                    <p className="privacy_policy_paragraph">The Company may disclose Your Data in the good faith belief that such action is necessary to:</p>
                    <ul>
                        <li className="privacy_policy_paragraph">Comply with a legal obligation.</li>
                        <li className="privacy_policy_paragraph">Protect and defend the rights or property of the Company.</li>
                        <li className="privacy_policy_paragraph">Prevent or investigate possible wrongdoing in connection with the Service.</li>
                        <li className="privacy_policy_paragraph">Protect the personal safety of Users of the Service or the public.</li>
                        <li className="privacy_policy_paragraph">Protect against legal liability.</li>
                    </ul>
                    <h2 className="privacy_policy_heading">Security of Your Data</h2>
                    <p className="privacy_policy_paragraph">The security of Your Data is important to Us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Data, We cannot guarantee its absolute security.</p>
                    <h1 className="privacy_policy_heading">Children's Privacy</h1>
                    <p className="privacy_policy_paragraph">Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under 18.</p>
                    <h1 className="privacy_policy_heading">Links to Other Websites</h1>
                    <p className="privacy_policy_paragraph">Our Service may contain links to other websites that we do not operate. If You click on a third-party link, You will be directed to that third-party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                    <p className="privacy_policy_paragraph">We have no control over and assume no responsibility for any third-party sites or services' content, privacy policies or practices.</p>
                    <h1 className="privacy_policy_heading">Other Webistes used</h1>
                    <p className="privacy_policy_paragraph">Design My Night - <a href="https://www.designmynight.com">https://www.designmynight.com</a></p>
                    <p className="privacy_policy_paragraph">Privacy Policy - <a href="https://www.designmynight.com/privacy-notice">https://www.designmynight.com/privacy-notice</a></p>
                    <h1 className="privacy_policy_heading">Changes to this Privacy Policy</h1>
                    <p className="privacy_policy_paragraph">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
                    <p className="privacy_policy_paragraph">We will update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                    <p className="privacy_policy_paragraph">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when posted on this page.</p>
                    <h1 className="privacy_policy_heading">Contact Us</h1>
                    <p className="privacy_policy_paragraph">If you have any questions about this Privacy Policy, You can contact us:</p>
                    <ul>
                        <li>
                            <p className="privacy_policy_paragraph">By email: <a href="mailto: info@ripperandco.co.uk">info@ripperandco.co.uk</a></p>
                        </li>
                    </ul>

                </div>
            </div>

            <div className="bottom_section">
                <Footer />
            </div>
        </>
    )
}

export default Privacy_Policy;
