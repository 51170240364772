import React from 'react';
import './Header.css'
import Booknow from '../../component/Button/Booknow';
import Linebrake from '../../component/Linebrake/Linebrake';

function Header(props) {
    const text_1_class = (props.text_1_class !== undefined && props.text_1_class !== '') ? props.text_1_class : '';
    return (
        <>
            {
                (props.image_url !== '' && props.image_url !== undefined) &&
                <div className="glitch">
                    <div className="glitch-img" style={{ backgroundImage: `url(${props.image_url})` }}></div>
                    <div className="glitch-img" style={{ backgroundImage: `url(${props.image_url})` }}></div>
                    <div className="glitch-img" style={{ backgroundImage: `url(${props.image_url})` }}></div>
                    <div className="glitch-img" style={{ backgroundImage: `url(${props.image_url})` }}></div>
                    <div className="glitch-img" style={{ backgroundImage: `url(${props.image_url})` }}></div>
                </div>
            }

            <div className="container">
                <div className="header_content">
                    <h2 className={`heading_2 ${text_1_class}`}>{props.text_1}</h2>
                    <div className="mobile_line_break">
                        <Linebrake />
                    </div>
                    <h1 className="heading_1">
                        {props.text_2}
                    </h1>
                    <Booknow size="normal" />
                </div>
            </div >
        </>
    )
}

export default Header