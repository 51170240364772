import React from 'react';
import line_braker_ribon from '../../assets/icons/line_braker_ribon.svg';
import './Linebrake.css';

function Linebrake() {
    return (
        <div className='linebrake'>
            <div className="ribon_line"></div>
            <img className='ribon_img' src={line_braker_ribon} alt="line braker" />
            <div className="ribon_line"></div>
        </div>
    )
}

export default Linebrake;