import React from 'react';
import "./Drink.css";
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import { Helmet } from 'react-helmet-async';
import RipperSlider from '../../component/RipperSlider/RipperSlider';
import Booknow from '../../component/Button/Booknow';
import GallerySlider from '../../component/gallerySlider/gallerySlider';
import ripper_drink_image from '../../assets/images/ripper_drink_image.png';
// Menu Images
import page_1_ripper_drinks_menu from '../../assets/images/drinks/page_1_ripper_drinks_menu.png';
import page_2_ripper_drinks_menu from '../../assets/images/drinks/page_2_ripper_drinks_menu.png';
import page_3_ripper_drinks_menu from '../../assets/images/drinks/page_3_ripper_drinks_menu.png';
import page_4_ripper_drinks_menu from '../../assets/images/drinks/page_4_ripper_drinks_menu.png';
import page_5_ripper_drinks_menu from '../../assets/images/drinks/page_5_ripper_drinks_menu.png';
import page_6_ripper_drinks_menu from '../../assets/images/drinks/page_6_ripper_drinks_menu.png';
import page_7_ripper_drinks_menu from '../../assets/images/drinks/page_7_ripper_drinks_menu.png';
import page_8_ripper_drinks_menu from '../../assets/images/drinks/page_8_ripper_drinks_menu.png';
import page_9_ripper_drinks_menu from '../../assets/images/drinks/page_9_ripper_drinks_menu.png';
import page_10_ripper_drinks_menu from '../../assets/images/drinks/page_10_ripper_drinks_menu.png';
import page_11_ripper_drinks_menu from '../../assets/images/drinks/page_11_ripper_drinks_menu.png';
import page_12_ripper_drinks_menu from '../../assets/images/drinks/page_12_ripper_drinks_menu.png';
import page_13_ripper_drinks_menu from '../../assets/images/drinks/page_13_ripper_drinks_menu.png';
import page_14_ripper_drinks_menu from '../../assets/images/drinks/page_14_ripper_drinks_menu.png';
import page_15_ripper_drinks_menu from '../../assets/images/drinks/page_15_ripper_drinks_menu.png';
import page_16_ripper_drinks_menu from '../../assets/images/drinks/page_16_ripper_drinks_menu.png';
// DrinksPortrait
import DSC_4558 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4558.jpg';
import DSC_4564 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4564.jpg';
import DSC_4591 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4591.jpg';
import DSC_4608 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4608.jpg';
import DSC_4629 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4629.jpg';
import DSC_4638 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4638.jpg';
import DSC_4647 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4647.jpg';
import DSC_4672 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4672.jpg';
import DSC_4689 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4689.jpg';
import DSC_4936 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4936.jpg';
import DSC_4949 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4949.jpg';
import DSC_4956 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4956.jpg';
import DSC_4961 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4961.jpg';
import DSC_4966 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4966.jpg';
import DSC_4971 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4971.jpg';
// DrinksLandscape
import DSC_4569 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4569.jpg';
import DSC_4580 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4580.jpg';
import DSC_4601 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4601.jpg';
import DSC_4625 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4625.jpg';
import DSC_4640 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4640.jpg';
import DSC_4648 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4648.jpg';
import DSC_4665 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4665.jpg';
import DSC_4681 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4681.jpg';
import DSC_4690 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4690.jpg';
import DSC_4976 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4976.jpg';

function Drink() {
    const sliderImages = [
        page_1_ripper_drinks_menu,
        page_2_ripper_drinks_menu,
        page_3_ripper_drinks_menu,
        page_4_ripper_drinks_menu,
        page_5_ripper_drinks_menu,
        page_6_ripper_drinks_menu,
        page_7_ripper_drinks_menu,
        page_8_ripper_drinks_menu,
        page_9_ripper_drinks_menu,
        page_10_ripper_drinks_menu,
        page_11_ripper_drinks_menu,
        page_12_ripper_drinks_menu,
        page_13_ripper_drinks_menu,
        page_14_ripper_drinks_menu,
        page_15_ripper_drinks_menu,
        page_16_ripper_drinks_menu
    ]
    const drinkPort = [
        DSC_4558,
        DSC_4564,
        DSC_4591,
        DSC_4608,
        DSC_4629,
        DSC_4638,
        DSC_4647,
        DSC_4672,
        DSC_4689,
        DSC_4936,
        DSC_4949,
        DSC_4956,
        DSC_4961,
        DSC_4966,
        DSC_4971,
    ]
    const drinkLan = [
        DSC_4569,
        DSC_4580,
        DSC_4601,
        DSC_4625,
        DSC_4640,
        DSC_4648,
        DSC_4665,
        DSC_4681,
        DSC_4690,
        DSC_4976,
    ]
    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Drink | Book Now</title>
                <meta name="title" content="Ripper & Co. Southsea | Drink | Book Now" />
                <meta name="description"
                    content="Sip on chilling spirits, where horror's essence lingers, at Ripper & Co.'s haunting cocktail haven." />

                <meta property="og:title" content="Ripper & Co. Southsea | Drink | Book Now" />
                <meta property="og:description"
                    content="Sip on chilling spirits, where horror's essence lingers, at Ripper & Co.'s haunting cocktail haven." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Drink | Book Now" />
                <meta property="twitter:description"
                    content="Sip on chilling spirits, where horror's essence lingers, at Ripper & Co.'s haunting cocktail haven." />
            </Helmet>

            <header className='header'>
                <Header
                    image_url={ripper_drink_image}
                    text_1="Drink"
                    text_1_class=""
                    text_2="Sip on chilling spirits, where horror's essence lingers, at Ripper & Co.'s haunting cocktail haven."
                />
            </header>
            <div className="galleryslider">
                <GallerySlider sliderImages={drinkPort} />
            </div>
            <div className="slider_section">
                <div className="container">
                    <h1 className="heading_1_1">DRINKS MENU</h1>
                    <RipperSlider sliderImages={sliderImages} />
                </div>
            </div>
            <div className="galleryslider">
                <GallerySlider sliderImages={drinkLan} />
            </div>
            <div className="bottom_section">
                <div className="booknow_center_box">
                    <Booknow size="normal" />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Drink;
