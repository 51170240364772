import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo.svg';
import menu_open from '../../assets/icons/menu_open.svg';
import menu_close from '../../assets/icons/menu_close.svg';
import './Nav.css';
import Booknow from '../../component/Button/Booknow';
import { Link } from 'react-router-dom';

function Nav() {
    const [isMenuActive, setIsMenuActive] = useState(false);

    function scrollToTop() {
        window.scrollTo(0, 0);
        setIsMenuActive(false);
    }

    useEffect(() => {
        window.onscroll = function (e) {
            if (
                document.body.scrollTop > 50 ||
                document.documentElement.scrollTop > 50
            ) {
                document.querySelector(".nav").classList.add("nav_active");
            } else {
                document.querySelector(".nav").classList.remove("nav_active");
            }
        };

    }, []);

    return (
        <div className={`navigation` + (isMenuActive ? ' mobile_menu mobile_menu_active' : '')}>
            <div className="logo_and_menu_icon">
                <Link to="/" className=' navigation_link' onClick={scrollToTop}><img src={logo} className="logo_main" alt="logo" /></Link>
                <div className="mobile_menu_open" onClick={(e) => setIsMenuActive(!isMenuActive)}>
                    <img src={menu_open} alt="menu open" className='mobile_menu_open_icon' />
                    <img src={menu_close} alt="menu close" className='mobile_menu_close_icon' />
                </div>
            </div>

            <ul className="main_menu">
                <li>
                    <Link to="/drink" className="main_menu_item navigation_link" onClick={scrollToTop}>Drink</Link>
                    {/* <a href="#" className="main_menu_item">Drink</a> */}
                </li>
                <li>
                    <Link to="/eat" className="main_menu_item navigation_link" onClick={scrollToTop}>Eat</Link>
                </li>
                <li>
                    <div className="dropdown">
                        <p className="main_menu_item dropdown_title">
                            What’s On
                            <span className='dropdown_arrow'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 11" fill="none">
                                    <path d="M11.5 1.17921C11.4913 1.22422 11.4825 1.27823 11.4651 1.30524C10.8971 1.70131 9.21088 4.3928 8.38086 5.86907C7.98769 6.57119 7.45473 7.59738 7.0004 8.55155C6.51112 9.58674 6.10921 10.5409 6.09174 10.901C6.083 10.919 6.06553 10.937 6.04805 10.955C6.03058 10.973 6.01311 10.991 5.99563 11C5.97816 10.991 5.96942 10.973 5.95195 10.955C5.93447 10.937 5.917 10.919 5.917 10.901C5.89079 10.5409 5.49762 9.58674 4.9996 8.55155C4.54527 7.59738 4.01231 6.57119 3.61914 5.86907C2.79786 4.3928 1.10286 1.70131 0.534948 1.30524C0.517474 1.27823 0.508737 1.22422 0.5 1.17921C0.692216 1.0982 1.05044 0.963175 1.53098 0.675123C2.02025 0.38707 2.31732 0.135025 2.47458 0C2.50953 0.0360065 2.55322 0.0720131 2.56195 0.099018C2.60564 0.783142 4.02979 3.65466 4.85981 5.13093C5.15687 5.66203 5.56751 6.35516 6.00437 7.03928C6.43249 6.35516 6.84313 5.66203 7.14019 5.13093C7.97021 3.65466 9.39436 0.783142 9.43805 0.099018C9.45552 0.0720131 9.49047 0.0360065 9.52542 0C9.69142 0.135025 9.98848 0.38707 10.4778 0.675123C10.9496 0.963175 11.3078 1.0982 11.5 1.17921Z" fill="#BEBEBE" />
                                </svg>
                            </span>
                        </p>
                        <ul className="dropdown_list">
                            <li>
                                <Link to="/fright-brunch" className="dropdown_list_item navigation_link" onClick={scrollToTop}>Fright Brunch</Link>
                            </li>
                            <li>
                                <Link to="/case-file-thursdays" className="dropdown_list_item navigation_link" onClick={scrollToTop}>Case File Thursdays</Link>
                            </li>
                            <li>
                                <Link to="/witching-hour" className="dropdown_list_item navigation_link" onClick={scrollToTop}>Witching Hour</Link>
                            </li>
                            <li>
                                <Link to="/halloween" className="dropdown_list_item navigation_link" onClick={scrollToTop}>Halloween</Link>
                            </li>
                            <li>
                                <Link to="/christmas" className="dropdown_list_item navigation_link" onClick={scrollToTop}>Christmas</Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <li>
                    <Link to="https://www.designmynight.com/vouchers/rapscallions/ripper-at-co" target='_blank' className="main_menu_item navigation_link" onClick={scrollToTop}>Buy Vouchers</Link>
                </li>
                <li>
                    <Link to="/contact" className="main_menu_item navigation_link" onClick={scrollToTop}>Contact Us</Link>
                </li>
                <li>
                    <Booknow size="small" />
                </li>
            </ul>
        </div>
    )
}

export default Nav;