import React from 'react';
import "./Fright_brunch.css";
import ripper_hannibal_image from '../../assets/images/ripper_hannibal_image.png';
import ripper_clock from '../../assets/images/ripper_clock.jpg';
import ripper_cocktails from '../../assets/images/ripper_cocktails.png';
import ripper_eat from '../../assets/images/ripper_eat.png';
import ripper_money from '../../assets/images/ripper_money.png';
import ripper_chef_image from '../../assets/images/ripper_chef_image.png';
import fright_brunch_menu_1 from '../../assets/images/fright-brunch/fright_brunch_menu_1.png';
import fright_brunch_menu_2 from '../../assets/images/fright-brunch/fright_brunch_menu_2.png';
import Booknow from '../../component/Button/Booknow';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import { Helmet } from 'react-helmet-async';
import GallerySlider from '../../component/gallerySlider/gallerySlider';
// Brunch
import DSC_4982 from '../../assets/images/galleryImages/Brunch/DSC_4982.jpg';
import DSC_4984 from '../../assets/images/galleryImages/Brunch/DSC_4984.jpg';
import DSC_4987 from '../../assets/images/galleryImages/Brunch/DSC_4987.jpg';
import DSC_4990 from '../../assets/images/galleryImages/Brunch/DSC_4990.jpg';
import RipperSlider from '../../component/RipperSlider/RipperSlider';
// Menu Images
import page_1_ripper_food_menu from '../../assets/images/food/page_1_ripper_food_menu.png';
import page_2_ripper_food_menu from '../../assets/images/food/page_2_ripper_food_menu.png';
import page_3_ripper_food_menu from '../../assets/images/food/page_3_ripper_food_menu.png';
import page_4_ripper_food_menu from '../../assets/images/food/page_4_ripper_food_menu.png';
import page_5_ripper_food_menu from '../../assets/images/food/page_5_ripper_food_menu.png';
import page_6_ripper_food_menu from '../../assets/images/food/page_6_ripper_food_menu.png';
import page_7_ripper_food_menu from '../../assets/images/food/page_7_ripper_food_menu.png';
import page_8_ripper_food_menu from '../../assets/images/food/page_8_ripper_food_menu.png';

function Fright_brunch() {
    const sliderImages = [
        fright_brunch_menu_1,
        fright_brunch_menu_2,
    ]
    const Brunch_slider_images = [
        DSC_4982,
        DSC_4984,
        DSC_4987,
        DSC_4990,
    ]

    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Fright Brunch | Book Now</title>
                <meta name="title" content="Ripper & Co. Southsea | Fright Brunch | Book Now" />
                <meta name="description"
                    content="Awaken your appetite for terror at the spine-chilling brunch where horror reigns and time stands still." />

                <meta property="og:title" content="Ripper & Co. Southsea | Fright Brunch | Book Now" />
                <meta property="og:description"
                    content="Awaken your appetite for terror at the spine-chilling brunch where horror reigns and time stands still." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Fright Brunch | Book Now" />
                <meta property="twitter:description"
                    content="Awaken your appetite for terror at the spine-chilling brunch where horror reigns and time stands still." />
            </Helmet>

            <header className='header'>
                <Header
                    image_url={ripper_hannibal_image}
                    text_1="FRIGHT BRUNCH"
                    text_1_class=""
                    text_2="Embark on a 90-Minute Nightmare: A Spine-Chilling Brunch where Horror Reigns and the Clock Ticks Down"
                />
            </header>
            <div className="special_feature">
                <div className="container">
                    <div className="special_feature_content">
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_clock})` }}>
                                {/* <img className='special_feature_img' src={ripper_clock} alt="ripper clock" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">CLOCK IS TICKING</h3>
                                <p className="special_feature_text">Let the 90 minute nightmare begin, savour the horror in each sinister bite.</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_cocktails})` }}>
                                {/* <img className='special_feature_img' src={ripper_cocktails} alt="ripper cocktails" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">SELECTED DRINKS</h3>
                                <p className="special_feature_text">Meticulously curated selection of unlimited cocktails, beers, and Prosecco, luring you to sip from the chalice of darkness.</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_eat})` }}>
                                {/* <img className='special_feature_img' src={ripper_eat} alt="ripper eat" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">HORROR BITES</h3>
                                <p className="special_feature_text">A malevolent medley of three chilling dishes per person, blending the realms of savory and sweet.</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_money})` }}>
                                {/* <img className='special_feature_img' src={ripper_money} alt="ripper money" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">£45 per person</h3>
                                <p className="special_feature_text">Dare you pay the ghastly toll of £45 per person to partake in this chilling feast of terror?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="galleryslider">
                <GallerySlider sliderImages={Brunch_slider_images} />
            </div> */}
            <div className="mb-40"></div>
            {/* <div className="slider_section">
                <div className="container">
                    <h1 className="heading_1_1">FOOD MENU</h1>
                    <p className="menu_instraction_text">Tap to view</p>
                    <RipperSlider sliderImages={sliderImages} />
                    <p className="menu_notice_text">This is a sample menu and may be subject to change</p>
                    <Booknow size="normal" />
                </div>
            </div> */}
            <div className="bottom_section">
                <div className="rules_section">
                    <div className="container">
                        <div className="rules_section_content fright_brunch_rules_section">
                            <div className="slider_section">
                                <h1 className="heading_1_1">FRIGHT BRUNCH MENU</h1>
                                <p className="menu_instraction_text">Tap to view</p>
                                <RipperSlider sliderImages={sliderImages} />
                                <p className="menu_notice_text">This is a sample menu and may be subject to change</p>
                                <div className="booknow_center_box">
                                    <Booknow size="normal" />
                                </div>
                            </div>
                            {/* <div className="rules_image_box">
                                <div className="fright_brunch_menu">
                                    <img className='fright_brunch_menu_image' src={ripper_brunch_menu} alt="ripper brunch menu" />
                                </div>
                                <div className="button_image">
                                    <img className='rules_image' src={ripper_chef_image} alt="ripper brunch chef" />
                                    <Booknow size="normal" />
                                </div>
                            </div> */}
                            <div className="rules_text_button">
                                <div className="rules_list_box">
                                    <h3 className="rules_title">Fright Brunch Rules</h3>
                                    <ol className='rules_list'>
                                        <li>
                                            <p className="rules_list_item_text">90 mins unlimited selected Cocktails, Prosecco and Beer.</p>
                                        </li>
                                        <li>
                                            <p className="rules_list_item_text">You will pay for your brunch in advance upon arrival</p>
                                        </li>
                                        <li>
                                            <p className="rules_list_item_text">Your cocktails are real and strong, so drink responsibly or your brunch will be cut short</p>
                                        </li>
                                        <li>
                                            <p className="rules_list_item_text">Enjoy your time with us, its going to be a SCREAM!</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Fright_brunch;
