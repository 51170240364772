import React from 'react';
import "./Case_file_thursdays.css";
import ripper_detective_image from '../../assets/images/ripper_detective_image.png';
import ripper_slash from '../../assets/images/ripper_slash.png';
import ripper_six from '../../assets/images/ripper_six.png';
import ripper_case_file from '../../assets/images/ripper_case_file.png';
import ripper_money from '../../assets/images/ripper_money.png';
import ripper_murder_mystery_image from '../../assets/images/ripper_murder_mystery_image.png';
import Booknow from '../../component/Button/Booknow';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import { Helmet } from 'react-helmet-async';
import GallerySlider from '../../component/gallerySlider/gallerySlider';
// FoodPortrait
import new_food_port_1 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_1.jpeg';
import new_food_port_2 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_2.jpeg';
import new_food_port_3 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_3.jpeg';
import new_food_port_4 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_4.jpeg';
import new_food_port_5 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_5.jpeg';
import new_food_port_6 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_6.jpeg';
import new_food_port_7 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_7.jpeg';
// DrinksPortrait
import DSC_4558 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4558.jpg';
import DSC_4564 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4564.jpg';
import DSC_4591 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4591.jpg';
import DSC_4608 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4608.jpg';
import DSC_4629 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4629.jpg';
import DSC_4638 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4638.jpg';
import DSC_4647 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4647.jpg';
import DSC_4672 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4672.jpg';
import DSC_4689 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4689.jpg';
import DSC_4936 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4936.jpg';
import DSC_4949 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4949.jpg';
import DSC_4956 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4956.jpg';
import DSC_4961 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4961.jpg';
import DSC_4966 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4966.jpg';
import DSC_4971 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4971.jpg';
// FoodLandscape
import new_food_lan_1 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_1.jpg';
import new_food_lan_2 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_2.jpg';
import new_food_lan_3 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_3.jpg';
import new_food_lan_4 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_4.jpg';
import new_food_lan_5 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_5.jpg';
import new_food_lan_6 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_6.jpg';
import new_food_lan_7 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_7.jpeg';
// DrinksLandscape
import DSC_4569 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4569.jpg';
import DSC_4580 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4580.jpg';
import DSC_4601 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4601.jpg';
import DSC_4625 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4625.jpg';
import DSC_4640 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4640.jpg';
import DSC_4648 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4648.jpg';
import DSC_4665 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4665.jpg';
import DSC_4681 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4681.jpg';
import DSC_4690 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4690.jpg';
import DSC_4976 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4976.jpg';

function Case_file_thursdays() {
    const foodDrinkPort = [
        new_food_port_1,
        new_food_port_2,
        new_food_port_3,
        new_food_port_4,
        new_food_port_5,
        new_food_port_6,
        new_food_port_7,
        DSC_4558,
        DSC_4564,
        DSC_4591,
        DSC_4608,
        DSC_4629,
        DSC_4638,
        DSC_4647,
        DSC_4672,
        DSC_4689,
        DSC_4936,
        DSC_4949,
        DSC_4956,
        DSC_4961,
        DSC_4966,
        DSC_4971,
    ]
    const foodDrinkLan = [
        new_food_lan_1,
        new_food_lan_2,
        new_food_lan_3,
        new_food_lan_4,
        new_food_lan_5,
        new_food_lan_6,
        new_food_lan_7,
        DSC_4569,
        DSC_4580,
        DSC_4601,
        DSC_4625,
        DSC_4640,
        DSC_4648,
        DSC_4665,
        DSC_4681,
        DSC_4690,
        DSC_4976,
    ]
    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Case File Thursdays | Book Now</title>
                <meta name="title" content="Ripper & Co. Southsea | Case File Thursdays | Book Now" />
                <meta name="description"
                    content="Unleash your detective skills, uncover the truth, and slash your bill in half at this murder mystery adventure." />

                <meta property="og:title" content="Ripper & Co. Southsea | Case File Thursdays | Book Now" />
                <meta property="og:description"
                    content="Unleash your detective skills, uncover the truth, and slash your bill in half at this murder mystery adventure." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Case File Thursdays | Book Now" />
                <meta property="twitter:description"
                    content="Unleash your detective skills, uncover the truth, and slash your bill in half at this murder mystery adventure." />
            </Helmet>

            <header className='header'>
                <Header
                    image_url={ripper_detective_image}
                    text_1="Case file Thursdays"
                    text_1_class=""
                    text_2="Unleash your detective skills, uncover the truth, and slash your bill in half at this murder mystery adventure."
                />
            </header>

            <div className="special_feature">
                <div className="container">
                    <div className="special_feature_content">
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_slash})` }}>
                                {/* <img className='special_feature_img' src={ripper_slash} alt="ripper clock" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">Slash your Bill</h3>
                                <p className="special_feature_text">Embrace the pulse-pounding challenge, solve the murder mystery within 2 hours, and relish a chilling 50% off.</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_six})` }}>
                                {/* <img className='special_feature_img' src={ripper_six} alt="ripper cocktails" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">Up to 6 People</h3>
                                <p className="special_feature_text">Engage your sharpest minds as a group of up to 6 delves into a murder mystery, unraveling the sinister truth together.</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_case_file})` }}>
                                {/* <img className='special_feature_img' src={ripper_case_file} alt="ripper eat" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">Solve the Case</h3>
                                <p className="special_feature_text">Immerse yourself in a world of intrigue as you savor drinks and dinner, armed with a captivating case file at your table.</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_money})` }}>
                                {/* <img className='special_feature_img' src={ripper_money} alt="ripper money" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">£30 per team</h3>
                                <p className="special_feature_text">Step into the realm of deceit and suspicion, priced at £30 per team, for an evening of murderous intrigue.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="galleryslider">
                <GallerySlider sliderImages={foodDrinkLan} />
            </div>
            <div className="bottom_section">
                <div className="rules_section">
                    <div className="container">
                        <div className="rules_section_content">
                            <div className="rules_image_box">
                                <img className='rules_image' src={ripper_murder_mystery_image} alt="ripper mystery" />
                            </div>
                            <div className="galleryslider">
                                <GallerySlider sliderImages={foodDrinkPort} />
                            </div>
                            <div className="rules_text_button">
                                <div className="rules_list_box">
                                    <h3 className="rules_title">Few Rules</h3>
                                    <ol className='rules_list'>
                                        <li>
                                            <p className="rules_list_item_text">£30 payment will be received from you before the case is handed over.</p>
                                        </li>
                                        <li>
                                            <p className="rules_list_item_text">No helping any other souls away from your table</p>
                                        </li>
                                        <li>
                                            <p className="rules_list_item_text">Any wrong doing or suspected cheating will not go unpunished</p>
                                        </li>
                                        <li>
                                            <p className="rules_list_item_text">If you do find our killer in under 2 hours the bill at that point will be discounted by 50%, one minute over and we will want all your money!!</p>
                                        </li>
                                    </ol>
                                    <p className="rules_note">*We are working in partnership with one of the best case file providers in the world, to provide the best experience for you.</p>
                                </div>
                                <Booknow size="normal" />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Case_file_thursdays;
