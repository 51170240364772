import Home from '../../pages/Home/Home';
import './App.css';
import './BookingWidget.css';
import Nav from '../../component/Nav/Nav';
import Linebrake from '../../component/Linebrake/Linebrake';
import Footer from '../Footer/Footer';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import metaIMG from '../../assets/images/meta_image.png';
import Fright_brunch from '../../pages/Fright_brunch/Fright_brunch';
import { Route, Routes, Navigate } from 'react-router-dom';
import Case_file_thursdays from '../../pages/Case_file_thursdays/Case_file_thursdays';
import Witching_hour from '../../pages/Witching_hour/Witching_hour';
import Contact from '../../pages/Contact/Contact';
import Eat from '../../pages/Eat/Eat';
import Drink from '../../pages/Drink/Drink';
import Privacy_Policy from '../../pages/Privacy_Policy/Privacy_Policy';
import Halloween from '../../pages/Halloween/Halloween';
import Christmas from '../../pages/Christmas/Christmas';
import CookieBanner from '../CookieBanner/CookieBanner';

function App() {
  return (
    <HelmetProvider >
      <Helmet>
        <title>Ripper & Co. Southsea | Classic Horror Bar & Restaurant</title>
        <meta name="keywords"
          content="Classic, Cocktail, Horror, Bar, Restaurant, Food, Drink, Brunch, Murder Mystery, Happy Hour, Southsea, Portsmouth, Hampshire" />
        <meta name="title" content="Ripper & Co. Southsea | Classic Horror Bar & Restaurant" />
        <meta name="description"
          content="Step into the chilling world of Ripper & Co a classic cocktail horror bar / restaurant where fright meets flavour in our haunting dining experience. Located in Southsea, Portsmouth" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ripperandco.co.uk/" />
        <meta property="og:title" content="Ripper & Co. Southsea | Classic Horror Bar & Restaurant" />
        <meta property="og:description"
          content="Step into the chilling world of Ripper & Co a classic cocktail horror bar / restaurant where fright meets flavour in our haunting dining experience. Located in Southsea, Portsmouth" />
        <meta property="og:image" content={metaIMG} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ripperandco.co.uk/" />
        <meta property="twitter:title" content="Ripper & Co. Southsea | Classic Horror Bar & Restaurant" />
        <meta property="twitter:description"
          content="Step into the chilling world of Ripper & Co a classic cocktail horror bar / restaurant where fright meets flavour in our haunting dining experience. Located in Southsea, Portsmouth" />
        <meta property="twitter:image" content={metaIMG} />
      </Helmet>

      <div className='page_wrapper'>
        <nav className="nav" >
          <div className="outer_container top_linebreak">
            <Linebrake />
          </div>
          <div className="container">
            <Nav></Nav>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route exact path='/eat' element={<Eat />} />
          <Route exact path='/drink' element={<Drink />} />
          <Route exact path='/fright-brunch' element={<Fright_brunch />} />
          <Route exact path='/case-file-thursdays' element={<Case_file_thursdays />} />
          <Route exact path='/witching-hour' element={<Witching_hour />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/privacy-policy' element={<Privacy_Policy />} />
          <Route exact path='/halloween' element={<Halloween />} />
          <Route exact path='/christmas' element={<Christmas />} />
          <Route path='/*' element={<Navigate to="/" replace={true} />} />
        </Routes>

        <CookieBanner />
      </div>
    </HelmetProvider>
  );
}

export default App;
