function Subscribe(data) {
    return fetch('https://api.ripperandco.co.uk/api/user', {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(data)
    }).then(response => {
        // console.log("response", response);
        // return response.ok;
        return response;
    }).catch(err => console.log(err))
}

export default Subscribe;