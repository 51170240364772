import React from 'react';
import "./Eat.css";
import ripper_eat_image from '../../assets/images/ripper_eat_image.png';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import { Helmet } from 'react-helmet-async';
import RipperSlider from '../../component/RipperSlider/RipperSlider';
import Booknow from '../../component/Button/Booknow';
import GallerySlider from '../../component/gallerySlider/gallerySlider';
// Menu Images
import page_1_ripper_food_menu from '../../assets/images/food/page_1_ripper_food_menu.png';

import tapas from '../../assets/images/food/tapas.png';
import tapas_2 from '../../assets/images/food/tapas_2.png';
import desserts from '../../assets/images/food/desserts.png';

import sunday_menu from '../../assets/images/food/sunday/sunday_menu.png';
import sunday_menu_2 from '../../assets/images/food/sunday/sunday_menu_2.png';

import page_2_ripper_food_menu from '../../assets/images/food/page_2_ripper_food_menu.png';
import page_3_ripper_food_menu from '../../assets/images/food/page_3_ripper_food_menu.png';
import page_4_ripper_food_menu from '../../assets/images/food/page_4_ripper_food_menu.png';
import page_5_ripper_food_menu from '../../assets/images/food/page_5_ripper_food_menu.png';
import page_6_ripper_food_menu from '../../assets/images/food/page_6_ripper_food_menu.png';
import page_7_ripper_food_menu from '../../assets/images/food/page_7_ripper_food_menu.png';
import page_8_ripper_food_menu from '../../assets/images/food/page_8_ripper_food_menu.png';

// FoodPortrait
import new_food_port_1 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_1.jpeg';
import new_food_port_2 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_2.jpeg';
import new_food_port_3 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_3.jpeg';
import new_food_port_4 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_4.jpeg';
import new_food_port_5 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_5.jpeg';
import new_food_port_6 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_6.jpeg';
import new_food_port_7 from '../../assets/images/galleryImages/FoodPortrait/new_food_port_7.jpeg';
// FoodLandscape
import new_food_lan_1 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_1.jpg';
import new_food_lan_2 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_2.jpg';
import new_food_lan_3 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_3.jpg';
import new_food_lan_4 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_4.jpg';
import new_food_lan_5 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_5.jpg';
import new_food_lan_6 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_6.jpg';
import new_food_lan_7 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_7.jpeg';


function Eat() {
    const sliderImages = [
        page_1_ripper_food_menu,
        tapas,
        tapas_2,
        desserts,
        // page_2_ripper_food_menu,
        // page_3_ripper_food_menu,
        // page_4_ripper_food_menu,
        // page_5_ripper_food_menu,
        // page_6_ripper_food_menu,
        // page_7_ripper_food_menu,
        // page_8_ripper_food_menu
    ]
    const sliderImages_sunday = [
        sunday_menu,
        sunday_menu_2,
    ]
    const foodPort = [
        new_food_port_1,
        new_food_port_2,
        new_food_port_3,
        new_food_port_4,
        new_food_port_5,
        new_food_port_6,
        new_food_port_7,
    ]
    const foodLan = [
        new_food_lan_1,
        new_food_lan_2,
        new_food_lan_3,
        new_food_lan_4,
        new_food_lan_5,
        new_food_lan_6,
        new_food_lan_7,
    ]
    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Eat | Book Now</title>
                <meta name="title" content="Ripper & Co. Southsea | Eat | Book Now" />
                <meta name="description"
                    content="Unearth fear-inspiring flavoUrs at the spine-chilling restaurant, where horror's essence infuses every dish." />

                <meta property="og:title" content="Ripper & Co. Southsea | Eat | Book Now" />
                <meta property="og:description"
                    content="Unearth fear-inspiring flavoUrs at the spine-chilling restaurant, where horror's essence infuses every dish." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Eat | Book Now" />
                <meta property="twitter:description"
                    content="Unearth fear-inspiring flavoUrs at the spine-chilling restaurant, where horror's essence infuses every dish." />
            </Helmet>

            <header className='header'>
                <Header
                    image_url={ripper_eat_image}
                    text_1="Eat"
                    text_1_class=""
                    text_2="Unearth fear-inspiring flavoUrs at the spine-chilling restaurant, where horror's essence infuses every dish."
                />
            </header>
            <div className="galleryslider">
                <GallerySlider sliderImages={foodLan} />
            </div>
            <div className="slider_section">
                <div className="container">
                    <h1 className="heading_1_1">FOOD MENU</h1>
                    <p className="menu_instraction_text">Tap to view</p>
                    <RipperSlider sliderImages={sliderImages} />
                    <p className="menu_notice_text">This is a sample menu and may be subject to change</p>
                </div>
            </div>
            <br /><br /><br />
            <div className="slider_section">
                <div className="container">
                    <h1 className="heading_1_1">SUNDAY FOOD</h1>
                    <p className="menu_instraction_text">Tap to view</p>
                    <RipperSlider sliderImages={sliderImages_sunday} />
                    <p className="menu_notice_text">This is a sample menu and may be subject to change</p>
                </div>
            </div>
            <div className="galleryslider">
                <GallerySlider sliderImages={foodPort} />
            </div>
            <div className="bottom_section">
                <div className="booknow_center_box">
                    <Booknow size="normal" />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Eat;
