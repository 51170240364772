import React, { useState } from 'react';
import "./CookieBanner.css";
import menu_close from '../../assets/icons/menu_close.svg';

function CookieBanner() {
    const [IsCookieBannerShown, setisCookieBannerShown] = useState(true);

    return (
        <>
            {
                IsCookieBannerShown &&
                <div className='cookieBannerWrapper'>
                    <div className="container">
                        <div className="cookieBanner">
                            <p className='cookieBanner_text'>By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.</p>
                            <span className="cookieBanner_close" onClick={() => setisCookieBannerShown(false)}>
                                <img src={menu_close} alt="close" />
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CookieBanner;