import React, { useRef, useState } from 'react';
import "./Home.css";
import ripper_arrow_down from '../../assets/icons/ripper_arrow_down.svg';
import ripper_freddy_image from '../../assets/images/ripper_freddy_image.png';
import ripper_hannibal_image from '../../assets/images/ripper_hannibal_image.png';
import ripper_detective_image from '../../assets/images/ripper_detective_image.png';
import ripper_witching_image from '../../assets/images/ripper_witching_image.png';
import ButtonPrimary from '../../component/Button/ButtonPrimary/ButtonPrimary';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import metaIMG from '../../assets/images/meta_image.png';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Subscribe from '../../helper/Subscribe';
import GallerySlider from '../../component/gallerySlider/gallerySlider';
// VenuePortrait
import DSC_4773 from '../../assets/images/galleryImages/VenuePortrait/DSC_4773.jpg';
import DSC_4834 from '../../assets/images/galleryImages/VenuePortrait/DSC_4834.jpg';
import DSC_4844 from '../../assets/images/galleryImages/VenuePortrait/DSC_4844.jpg';
import DSC_4895 from '../../assets/images/galleryImages/VenuePortrait/DSC_4895.jpg';
// VenueLandscape
import DSC_4693 from '../../assets/images/galleryImages/VenueLandscape/DSC_4693.jpg';
import DSC_4699 from '../../assets/images/galleryImages/VenueLandscape/DSC_4699.jpg';
import DSC_4710 from '../../assets/images/galleryImages/VenueLandscape/DSC_4710.jpg';
import DSC_4713 from '../../assets/images/galleryImages/VenueLandscape/DSC_4713.jpg';
import DSC_4715 from '../../assets/images/galleryImages/VenueLandscape/DSC_4715.jpg';
import DSC_4720 from '../../assets/images/galleryImages/VenueLandscape/DSC_4720.jpg';
import DSC_4831 from '../../assets/images/galleryImages/VenueLandscape/DSC_4831.jpg';
import DSC_4836 from '../../assets/images/galleryImages/VenueLandscape/DSC_4836.jpg';
import DSC_4842 from '../../assets/images/galleryImages/VenueLandscape/DSC_4842.jpg';
// FoodLandscape
import new_food_lan_1 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_1.jpg';
import new_food_lan_2 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_2.jpg';
import new_food_lan_3 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_3.jpg';
import new_food_lan_4 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_4.jpg';
import new_food_lan_5 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_5.jpg';
import new_food_lan_6 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_6.jpg';
import new_food_lan_7 from '../../assets/images/galleryImages/FoodLandscape/new_food_lan_7.jpeg';
// DrinksLandscape
import DSC_4569 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4569.jpg';
import DSC_4580 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4580.jpg';
import DSC_4601 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4601.jpg';
import DSC_4625 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4625.jpg';
import DSC_4640 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4640.jpg';
import DSC_4648 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4648.jpg';
import DSC_4665 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4665.jpg';
import DSC_4681 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4681.jpg';
import DSC_4690 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4690.jpg';
import DSC_4976 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4976.jpg';


function Home() {

    const myRef = useRef(null);
    const nameRef = useRef();
    const emailRef = useRef();
    const formRef = useRef();
    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isErrorText, setIsErrorText] = useState('');

    const feature_slider_images = [
        DSC_4773,
        DSC_4834,
        DSC_4844,
        DSC_4895,
        DSC_4693,
        DSC_4699,
        DSC_4710,
        DSC_4713,
        DSC_4715,
        DSC_4720,
        DSC_4831,
        DSC_4836,
        DSC_4842,
    ]
    const foodDrinkLan = [
        new_food_lan_1,
        new_food_lan_2,
        new_food_lan_3,
        new_food_lan_4,
        new_food_lan_5,
        new_food_lan_6,
        new_food_lan_7,
        DSC_4569,
        DSC_4580,
        DSC_4601,
        DSC_4625,
        DSC_4640,
        DSC_4648,
        DSC_4665,
        DSC_4681,
        DSC_4690,
        DSC_4976,
    ]

    const executeScroll = () => myRef.current.scrollIntoView();
    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    function handleSubmit(e) {
        setIsSuccess(false);
        setIsError(false);
        e.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        setIsSending(true);
        Subscribe({ name, email }).then(res => {
            setIsSending(false);
            if (res.ok) {
                // console.log("Success !!", res);
                setIsSuccess(true);
                setIsError(false);
                formRef.current.reset();
            } else if (res.status === 409) {
                // console.log("Error", res);
                setIsSuccess(false);
                setIsError(true);
                setIsErrorText("E-mail address is already registered");
            } else {
                // console.log("Error", res);
                setIsSuccess(false);
                setIsError(true);
                setIsErrorText("Something went wrong! please try again.");
            }
        }).catch(() => {
            // console.log("error in sending data");
            setIsSuccess(false);
            setIsError(true);
            setIsErrorText("Something went wrong! please try again.");
        });
    }


    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Classic Horror Bar & Restaurant</title>
                <meta name="keywords"
                    content="Classic, Cocktail, Horror, Bar, Restaurant, Food, Drink, Brunch, Murder Mystery, Happy Hour, Southsea, Portsmouth, Hampshire" />
                <meta name="title" content="Ripper & Co. Southsea | Classic Horror Bar & Restaurant" />
                <meta name="description"
                    content="Step into the chilling world of Ripper & Co., a classic cocktail horror bar and restaurant where fright meets flavour in our haunting dining experience." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ripperandco.co.uk/" />
                <meta property="og:title" content="Ripper & Co. Southsea | Classic Horror Bar & Restaurant" />
                <meta property="og:description"
                    content="Step into the chilling world of Ripper & Co., a classic cocktail horror bar and restaurant where fright meets flavour in our haunting dining experience." />
                <meta property="og:image" content={metaIMG} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ripperandco.co.uk/" />
                <meta property="twitter:title" content="Ripper & Co. Southsea | Classic Horror Bar & Restaurant" />
                <meta property="twitter:description"
                    content="Step into the chilling world of Ripper & Co., a classic cocktail horror bar and restaurant where fright meets flavour in our haunting dining experience." />
                <meta property="twitter:image" content={metaIMG} />
            </Helmet>

            <header className='header'>
                <Header
                    image_url={ripper_freddy_image}
                    text_1="Step into the chilling world of Ripper & Co."
                    text_1_class=""
                    text_2="a classic cocktail horror bar and restaurant where fright meets flavour in A haunting dining experience."
                />
                <div className="brave_box" onClick={executeScroll}>
                    <p className="brave_text">Are you brave enough?</p>
                    <img className='brave_go_down' src={ripper_arrow_down} alt="arrow down" />
                </div>
            </header>
            <div className="features" ref={myRef}>
                <div className="container">
                    <div className="features_content">
                        <div className="features_single">
                            <img src={ripper_hannibal_image} alt="ripper hannibal image" className="features_single_img" />
                            <h3 className="features_single_title">FRIGHT brunch</h3>
                            <p className="features_single_description">
                                Embark on a 90-Minute Nightmare: A Spine-Chilling Brunch
                                where Horror Reigns and the Clock Ticks Down
                            </p>
                            <ButtonPrimary customClass="button_small" link="/fright-brunch" text="ENTER" />
                        </div>
                        <div className="features_single">
                            <img src={ripper_detective_image} alt="ripper detective image" className="features_single_img" />
                            <h3 className="features_single_title">Case File Thursdays</h3>
                            <p className="features_single_description">
                                Unleash your detective skills, uncover the truth,
                                and slash your bill in half at this murder mystery adventure.
                            </p>
                            <ButtonPrimary customClass="button_small" link="/case-file-thursdays" text="ENTER" />
                        </div>
                        <div className="features_single">
                            <img src={ripper_witching_image} alt="ripper witching image" className="features_single_img" />
                            <h3 className="features_single_title witching_hour_text">Happy Hour</h3>
                            <p className="features_single_description">
                                Indulge in a nightmarish delight, as the clock strikes late,
                                and horrors await during our happy hourS.
                            </p>
                            <ButtonPrimary customClass="button_small" link="/witching-hour" text="ENTER" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="galleryslider">
                <GallerySlider sliderImages={feature_slider_images} />
            </div>
            <div className="resturent_menu">
                <div className="container">
                    <div className="resturent_menu_content">
                        <div className="resturent_menu_single eat">
                            <h3 className="resturent_menu_single_title">Eat</h3>
                            <ButtonPrimary customClass="text_400 fw_16 fw_xs_11" link="/eat" text="Find out more" />
                        </div>
                        <div className="resturent_menu_single drink">
                            <h3 className="resturent_menu_single_title">DRINK</h3>
                            <ButtonPrimary customClass="text_400 fw_16 fw_xs_11" link="/drink" text="Find out more" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="galleryslider">
                <GallerySlider sliderImages={foodDrinkLan} />
            </div>
            <div className="bottom_section">
                <div className="newsletter">
                    <div className="container">
                        <div className="newsletter_content">
                            <h1 className="newsletter_title">
                                Sign up for access to priority bookings, special offers and be the first to know...
                            </h1>
                            <form ref={formRef} className="from newsletter_form" onSubmit={(e) => handleSubmit(e)}>
                                <fieldset className="fieldset name_fieldset">
                                    <label htmlFor="name" className='from_label'>Full Name (as per the name on your ID)</label>
                                    <input ref={nameRef} className='from_input' type="text" name="name" id="name" placeholder='Full Name' minLength={2} autoComplete="off" required />
                                </fieldset>
                                <fieldset className="fieldset email_fieldset">
                                    <label htmlFor="email" className='from_label'>Email</label>
                                    <input ref={emailRef} className='from_input' type="email" name="email" id="email" placeholder='mymail@gmail.com' autoComplete="off" required />
                                </fieldset>
                                <p className="form_privacy">By clicking subscribe you accept the terms in our <Link to="/privacy-policy" className="form_privacy" onClick={scrollToTop}>Privacy Policy</Link></p>

                                <div className="form_info_message_box">
                                    {
                                        isSending &&
                                        <p className='form_status_text form_status_indication_processing'>
                                            Sending...
                                        </p>
                                    }
                                    {
                                        isSuccess &&
                                        <p className='form_status_text form_status_indication_successful'>
                                            Subscription Successful, Thank You
                                        </p>
                                    }
                                    {
                                        isError &&
                                        <p className='form_status_text form_status_indication_error'>
                                            {isErrorText}
                                        </p>
                                    }
                                </div>
                                <button type="submit" className="from_submit button_primary newsletter_form_submit">SUBSCRIBE</button>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Home;
