import React, { useEffect, useState } from 'react';
import "./RipperSlider.css";
import Slider from "react-slick";
import ripper_arrow_left from '../../assets/icons/ripper_arrow_left.svg';
import ripper_arrow_right from '../../assets/icons/ripper_arrow_right.svg';
import menu_close from '../../assets/icons/menu_close.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function RipperSlider({ sliderImages }) {
    // const mobileWindoeSize = 991;
    // const [isMobileView, setIsMobileView] = useState(false);
    const [isImageOpen, setIsImageOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    // const [dimensions, setDimensions] = React.useState({
    //     height: window.innerHeight,
    //     width: window.innerWidth
    // });


    // useEffect(() => {
    //     if (dimensions.width < mobileWindoeSize) {
    //         setIsMobileView(true);
    //     } else {
    //         setIsMobileView(false);
    //     };
    //     // 
    // }, [dimensions.width]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    function closeImagePreview() {
        setIsImageOpen(false);
        setSelectedImage(null);
    }

    function openImagePreview(index) {
        console.log(index)
        setSelectedImage(index);
        setIsImageOpen(true);
    }

    return (
        <>
            <Slider {...settings}>
                {
                    sliderImages.map((element, index) => {
                        return (
                            <div className='slider_img_box' key={index}>
                                <img onClick={() => openImagePreview(index)} className='slider_img' src={element} alt={`page_${index + 1}_ripper_drinks_menu`} />
                            </div>
                        )

                    })
                }
            </Slider>
            {
                isImageOpen &&
                <div className="fullPage_img_view">
                    <div className="blur"></div>
                    <div className="fullPage_img_view_content">
                        <img className='fullPage_img_close_icon' src={menu_close} alt="close" onClick={closeImagePreview} />
                        <div className="fullPage_img">
                            <img src={sliderImages[selectedImage]} alt="Image preview" />
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <img src={ripper_arrow_right} alt="Right" className={`slider_navigation_icon ${className}`} onClick={onClick} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div>
            <img src={ripper_arrow_left} alt="Left" className={`slider_navigation_icon ${className}`} onClick={onClick} />
        </div>
    );
}

export default RipperSlider;
