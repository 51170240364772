import React from 'react';
import './Footer.css';
import logo from '../../assets/logo.svg';
import facebook_icon from '../../assets/icons/facebook_icon.svg';
import triick_logo_web_gif from '../../assets/icons/triick_logo_web.gif';
import insta_icon from '../../assets/icons/insta_icon.svg';
import Linebrake from '../Linebrake/Linebrake';
import { Link } from 'react-router-dom';

function Footer() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    return (
        <footer className="footer">
            <div className="container">
                <Linebrake />
                <div className="footer_content_wrap">
                    <div className="footer_content">
                        <Link to="/" className=' navigation_link' onClick={scrollToTop}><img src={logo} alt="logo" className='footer_logo' /></Link>
                        <div className="footer_content_details">
                            <div className="footer_details_single">
                                <h4 className="footer_single_title">Location</h4>
                                <div className="footer_single_text_box">
                                    <p className="footer_single_text">46 Osborne Rd,</p>
                                    <p className="footer_single_text">Southsea,</p>
                                    <p className="footer_single_text">Portsmouth,</p>
                                    <p className="footer_single_text">PO5 3LT</p>
                                </div>
                            </div>
                            <div className="footer_details_single">
                                <h4 className="footer_single_title">What’s On</h4>
                                <div className="footer_single_text_box">
                                    <Link to="/witching-hour" className="footer_single_text navigation_link" onClick={scrollToTop}>The Witching Hour</Link>
                                    <Link to="/fright-brunch" className="footer_single_text navigation_link" onClick={scrollToTop}>Fright Brunch</Link>
                                    <Link to="/case-file-thursdays" className="footer_single_text navigation_link" onClick={scrollToTop}>Case File Thursdays</Link>
                                    <Link to="/eat" className="footer_single_text navigation_link" onClick={scrollToTop}>Eat</Link>
                                    <Link to="/drink" className="footer_single_text navigation_link" onClick={scrollToTop}>Drink</Link>
                                    <Link to="/halloween" className="footer_single_text navigation_link" onClick={scrollToTop}>Halloween</Link>
                                </div>
                            </div>
                            <div className="footer_details_single">
                                <h4 className="footer_single_title">Opening Hours</h4>
                                <div className="footer_single_text_box">
                                    <p className="footer_single_text">Monday & Tuesday - Closed</p>
                                    <p className="footer_single_text">Wednesday, Thursday & Friday 16:00 - 00:00</p>
                                    <p className="footer_single_text">Saturday & Sunday 12:00 - 00:00</p>
                                </div>
                            </div>
                            <div className="footer_details_single">
                                <h4 className="footer_single_title">Evil Stuff</h4>
                                <div className="footer_single_text_box">
                                    <Link to="/privacy-policy" className="footer_single_text navigation_link" onClick={scrollToTop}>Privacy Policy</Link>
                                    {/* <Link to="/" className="footer_single_text navigation_link" onClick={scrollToTop}>Cookie Policy</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer_copyright">
                        {/* <p className="footer_copyright_text">Copyright © 2023 Ripper & Co. Website powered by <a href="#" className="triick_logo">TRI/*CK.</a></p> */}
                        <div className="footer_copyright_text_box">
                            <p className="footer_copyright_text">Copyright © 2023 Ripper & Co. Website powered by</p>
                            <a href="https://triick.co.uk" target='_blank' className="triick_logo">
                                <img src={triick_logo_web_gif} alt="Triick.co.uk" />
                            </a>
                        </div>
                        <ul className="footer_social">
                            <li>
                                <a href="https://www.facebook.com/horrorbarsouthsea" target='_blank' className="footer_social_link">
                                    <img src={facebook_icon} alt="facebook icon" className="footer_social_icon" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/ripperandco/" className="footer_social_link" target='_blank'>
                                    <img src={insta_icon} alt="insta icon" className="footer_social_icon" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;