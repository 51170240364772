import React from 'react';
import "./Witching_hour.css";
import ripper_witching_image from '../../assets/images/ripper_witching_image.png';
import ripper_clock from '../../assets/images/ripper_clock.jpg';
import ripper_cocktails from '../../assets/images/ripper_cocktails.png';
import Booknow from '../../component/Button/Booknow';
import Footer from '../../component/Footer/Footer';
import Header from '../../component/Header/Header';
import { Helmet } from 'react-helmet-async';
import GallerySlider from '../../component/gallerySlider/gallerySlider';
// DrinksPortrait
import DSC_4558 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4558.jpg';
import DSC_4564 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4564.jpg';
import DSC_4591 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4591.jpg';
import DSC_4608 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4608.jpg';
import DSC_4629 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4629.jpg';
import DSC_4638 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4638.jpg';
import DSC_4647 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4647.jpg';
import DSC_4672 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4672.jpg';
import DSC_4689 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4689.jpg';
import DSC_4936 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4936.jpg';
import DSC_4949 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4949.jpg';
import DSC_4956 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4956.jpg';
import DSC_4961 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4961.jpg';
import DSC_4966 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4966.jpg';
import DSC_4971 from '../../assets/images/galleryImages/DrinksPortrait/DSC_4971.jpg';
// DrinksLandscape
import DSC_4569 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4569.jpg';
import DSC_4580 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4580.jpg';
import DSC_4601 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4601.jpg';
import DSC_4625 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4625.jpg';
import DSC_4640 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4640.jpg';
import DSC_4648 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4648.jpg';
import DSC_4665 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4665.jpg';
import DSC_4681 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4681.jpg';
import DSC_4690 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4690.jpg';
import DSC_4976 from '../../assets/images/galleryImages/DrinksLandscape/DSC_4976.jpg';

function Witching_hour() {
    const drinkPort = [
        DSC_4558,
        DSC_4564,
        DSC_4591,
        DSC_4608,
        DSC_4629,
        DSC_4638,
        DSC_4647,
        DSC_4672,
        DSC_4689,
        DSC_4936,
        DSC_4949,
        DSC_4956,
        DSC_4961,
        DSC_4966,
        DSC_4971,
    ]
    const drinkLan = [
        DSC_4569,
        DSC_4580,
        DSC_4601,
        DSC_4625,
        DSC_4640,
        DSC_4648,
        DSC_4665,
        DSC_4681,
        DSC_4690,
        DSC_4976,
    ]
    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Happy Witching Hour | Book Now</title>
                <meta name="title" content="Ripper & Co. Southsea | Happy Witching Hour | Book Now" />
                <meta name="description"
                    content="Indulge in a nightmarish delight, as the clock strikes late, and horrors await during our Witching hour." />

                <meta property="og:title" content="Ripper & Co. Southsea | Happy Witching Hour | Book Now" />
                <meta property="og:description"
                    content="Indulge in a nightmarish delight, as the clock strikes late, and horrors await during our Witching hour." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Happy Witching Hour | Book Now" />
                <meta property="twitter:description"
                    content="Indulge in a nightmarish delight, as the clock strikes late, and horrors await during our Witching hour." />
            </Helmet>

            <header className='header'>
                <Header
                    image_url={ripper_witching_image}
                    text_1="Happy Hour"
                    text_1_class="witching_hour_text"
                    text_2="Indulge in a nightmarish delight, as the clock strikes late, and horrors await during our Witching hourS."
                />
            </header>
            <div className="special_feature">
                <div className="container">
                    <div className="special_feature_content">
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_clock})` }}>
                                {/* <img className='special_feature_img' src={ripper_clock} alt="ripper clock" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">4pm till 6pm & 10pm till midnight</h3>
                                <p className="special_feature_text">Enter the realm of dread during the witching hour, 4pm till 6pm 10pm till midnight, on the eerie nights Sunday to Friday</p>
                            </div>
                        </div>
                        <div className="special_feature_single">
                            <div className="special_feature_img_box" style={{ backgroundImage: `url(${ripper_cocktails})` }}>
                                {/* <img className='special_feature_img' src={ripper_cocktails} alt="ripper cocktails" /> */}
                            </div>
                            <div className="special_feature_text_box">
                                <h3 className="special_feature_title">2 Cocktails for £14</h3>
                                <p className="special_feature_text">Indulge in the wicked elixirs, two cursed concoctions for a haunting £14, plucked from our vintage and director's cut collection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="galleryslider">
                <GallerySlider sliderImages={drinkPort} />
            </div>
            <div className="bottom_section">
                <div className="rules_section">
                    <div className="container">
                        <div className="rules_section_content">
                            <div className="rules_text_button happy_witching_hour_no_rule">
                                <Booknow size="normal" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="galleryslider">
                    <GallerySlider sliderImages={drinkLan} />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Witching_hour;
