import React, { useEffect } from 'react';
import './ButtonPrimary.css';
import { Link } from 'react-router-dom';

function ButtonPrimary({ link, text, customClass }) {
    let classNameAll;
    if (customClass !== undefined) {
        classNameAll = `button_primary ${customClass} navigation_link`
    }
    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <Link to={link} className={classNameAll} onClick={scrollToTop}>{text}</Link>
            {/* <a href={link} className={classNameAll}>{text}</a> */}
            {/* {
                (size === "normal") && <a href={link} className={classNameAll}>{text}</a>
            }
            {
                (size === "small") && <a href={link} className="button_primary button_small">{text}</a>
            } */}
        </>
    )
}

export default ButtonPrimary;