import React, { useEffect } from 'react';
import './Booknow.css';

function Booknow({ size }) {
    const bookingWrap = document.getElementById("booking_wrapper");
    const bookingClose = document.getElementById("booking_close");
    function openBooking() {
        bookingWrap.classList.add('active');
    }
    useEffect(() => {
        const handleClick = event => {
            bookingWrap.classList.remove('active');
        };

        bookingClose.addEventListener('click', handleClick);

        return () => {
            bookingClose.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <>
            {
                (size === "normal") && <button className="button_primary booknow_button" onClick={openBooking}>
                    <span>B</span>
                    <span>O</span>
                    <span>O</span>
                    <span>K</span>
                    <span>&nbsp;</span>
                    <span>N</span>
                    <span>O</span>
                    <span>W</span>
                </button>
            }
            {
                (size === "small") && <button className="button_primary booknow_button button_small" onClick={openBooking}>
                    <span>B</span>
                    <span>O</span>
                    <span>O</span>
                    <span>K</span>
                    <span>&nbsp;</span>
                    <span>N</span>
                    <span>O</span>
                    <span>W</span>
                </button>
            }
        </>
    )
}

export default Booknow;