import React, { useState } from 'react';
import "./Halloween.css";
import Footer from '../../component/Footer/Footer';
import Linebrake from '../../component/Linebrake/Linebrake';
import { Helmet } from 'react-helmet-async';
import Booknow from '../../component/Button/Booknow';
import halloweenHeader from '../../assets/images/halloween/ripper_halloween_web.png';
import halloween_title from '../../assets/images/halloween/halloween_title.svg';
import Halloween_web_schedule from '../../assets/images/halloween/Halloween_Week_Events.jpg';
import halloween_menu_ripped from '../../assets/images/halloween/halloween_menu_ripped.png';
import halloween_menu_2024 from '../../assets/images/halloween/Halloween_2024_Menu.png';
import halloween_menu from '../../assets/images/halloween/halloween_menu.jpeg';
import menu_close from '../../assets/icons/menu_close.svg';

function Halloween() {
    const [isImageOpen, setIsImageOpen] = useState(false);


    function closeImagePreview() {
        setIsImageOpen(false);
    }

    function openImagePreview() {
        setIsImageOpen(true);
    }

    return (
        <>
            <Helmet>
                <title>Ripper & Co. Presents Halloween</title>
                <meta name="keywords"
                    content="Ripper & Co., Halloween, Horror, Bar, Restaurant, Food, Drink, Brunch, Murder Mystery, Happy Hour, Southsea, Portsmouth, Hampshire" />
                <meta name="title" content="Ripper & Co. Presents Halloween" />
                <meta name="description"
                    content="Dive into a spine-tingling week of horror events at Ripper & Co. where we unravel chilling mysteries and protect your thrill-seeking soul with hair-raising experiences." />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ripperandco.co.uk/" />
                <meta property="og:title" content="Ripper & Co. Presents Halloween" />
                <meta property="og:description"
                    content="Dive into a spine-tingling week of horror events at Ripper & Co. where we unravel chilling mysteries and protect your thrill-seeking soul with hair-raising experiences." />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ripperandco.co.uk/" />
                <meta property="twitter:title" content="Ripper & Co. Presents Halloween" />
                <meta property="twitter:description"
                    content="Dive into a spine-tingling week of horror events at Ripper & Co. where we unravel chilling mysteries and protect your thrill-seeking soul with hair-raising experiences." />
            </Helmet>

            <div className="glitch">
                <div className="glitch-img" style={{ backgroundImage: `url(${halloweenHeader})` }}></div>
                <div className="glitch-img" style={{ backgroundImage: `url(${halloweenHeader})` }}></div>
                <div className="glitch-img" style={{ backgroundImage: `url(${halloweenHeader})` }}></div>
                <div className="glitch-img" style={{ backgroundImage: `url(${halloweenHeader})` }}></div>
                <div className="glitch-img" style={{ backgroundImage: `url(${halloweenHeader})` }}></div>
            </div>

            <header className='header halloween_header'>
                <div className="container">
                    <div className="header_content">
                        <h2 className="heading_2">Ripper & Co. Presents</h2>
                        <div className="mobile_line_break">
                            <Linebrake />
                        </div>
                        <img className='halloween_title' src={halloween_title} alt="halloween" />
                        <h1 className="heading_1">25th October - 3rd November</h1>
                    </div>
                </div>
                <div className="schedule_section">
                    <img className='halloween_web_schedule_img' src={Halloween_web_schedule} alt="Schedule" />
                </div>
            </header>

            <div className="booknow_center_box">
                <Booknow size="normal" />
            </div>

            <div className="halloween_menu">
                <div className="container">
                    <div className="halloween_menu_ripped_box">
                        <img src={halloween_menu_2024} alt="halloween_menu_ripped" onClick={openImagePreview} />
                    </div>

                    {
                        isImageOpen &&
                        <div className="galleryslider_img_view">
                            <div className="blur"></div>
                            <div className="galleryslider_img_view_content">
                                <img className='galleryslider_img_close_icon' src={menu_close} alt="close" onClick={closeImagePreview} />
                                <div className="galleryslider_preview_img">
                                    <img src={halloween_menu_2024} alt="Image preview" />
                                </div>
                            </div>
                        </div>
                    }

                    <div className="booknow_center_box">
                        <button className="button_primary booknow_button " onClick={openImagePreview} >
                            <span>V</span>
                            <span>I</span>
                            <span>E</span>
                            <span>W</span>
                            <span>&nbsp;</span>
                            <span>M</span>
                            <span>E</span>
                            <span>N</span>
                            <span>U</span>
                        </button>
                    </div>

                </div>
            </div>

            <div className="bottom_section">
                <Footer />
            </div>
        </>
    )
}

export default Halloween