import React, { useRef, useState } from 'react';
import "./Contact.css";
import Footer from '../../component/Footer/Footer';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import ContactUs from '../../helper/ContactUs';

function Contact() {
    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();
    const formRef = useRef();
    const [isSending, setIsSending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isErrorText, setIsErrorText] = useState('');

    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    function handleSubmit(e) {
        setIsSuccess(false);
        setIsError(false);
        e.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const message = messageRef.current.value;
        setIsSending(true);
        ContactUs({ name, email, message }).then(res => {
            // console.log(res)
            setIsSending(false);
            if (res.ok) {
                // console.log("Success !!", res);
                setIsSuccess(true);
                setIsError(false);
                formRef.current.reset();
            } else {
                // console.log("Error", res);
                setIsSuccess(false);
                setIsError(true);
                setIsErrorText("Something went wrong! please try again.");
            }
        }).catch(() => {
            // console.log("error in sending data");
            setIsSuccess(false);
            setIsError(true);
            setIsErrorText("Something went wrong! please try again.");
        });
    }

    return (
        <>
            <Helmet>
                <title>Ripper & Co. Southsea | Contact Us | Get in Touch</title>
                <meta name="title" content="Ripper & Co. Southsea | Contact Us | Get in Touch" />
                <meta name="description"
                    content="Enter the realm of darkness by reaching out to us today, where secrets lurk, and our haunted venue beckons. Enquire now." />

                <meta property="og:title" content="Ripper & Co. Southsea | Contact Us | Get in Touch" />
                <meta property="og:description"
                    content="Enter the realm of darkness by reaching out to us today, where secrets lurk, and our haunted venue beckons. Enquire now." />

                <meta property="twitter:title" content="Ripper & Co. Southsea | Contact Us | Get in Touch" />
                <meta property="twitter:description"
                    content="Enter the realm of darkness by reaching out to us today, where secrets lurk, and our haunted venue beckons. Enquire now." />
            </Helmet>

            <div className="bottom_section">
                <div className="container">
                    <div className="contact_content_wrap">
                        <h2 className="heading_2">Contact Us</h2>
                        <div className="contact_content">
                            <h1 className="heading_1">
                                Enter the realm of darkness by reaching out to us today, where secrets lurk, and our haunted venue beckons. Enquire now.
                            </h1>
                            <form ref={formRef} className="from contact_form" onSubmit={(e) => handleSubmit(e)}>
                                <fieldset className="fieldset name_fieldset">
                                    {/* <label htmlFor="name" className='from_label'>Full Name (as per the name on your ID)</label> */}
                                    <input ref={nameRef} className='from_input' type="text" name="name" id="name" placeholder='Full Name' minLength={2} autoComplete="off" required />
                                </fieldset>
                                <fieldset className="fieldset email_fieldset">
                                    {/* <label htmlFor="email" className='from_label'>Email</label> */}
                                    <input ref={emailRef} className='from_input' type="email" name="email" id="email" placeholder='mymail@gmail.com' autoComplete="off" required />
                                </fieldset>
                                <fieldset className="fieldset textarea_fieldset">
                                    {/* <label htmlFor="message" className='from_label'>Message</label> */}
                                    <textarea ref={messageRef} className='from_input' name="message" id="message" cols="30" rows="4" placeholder='Message' minLength={2} maxLength={300} autoComplete="off" required></textarea>
                                </fieldset>
                                <p className="form_privacy">By clicking submit you accept the terms in our <Link to="/privacy-policy" className="form_privacy" onClick={scrollToTop}>Privacy Policy</Link></p>

                                <div className="form_info_message_box">
                                    {
                                        isSending &&
                                        <p className='form_status_text form_status_indication_processing'>
                                            Sending...
                                        </p>
                                    }
                                    {
                                        isSuccess &&
                                        <p className='form_status_text form_status_indication_successful'>
                                            Message Sent, Thank You
                                        </p>
                                    }
                                    {
                                        isError &&
                                        <p className='form_status_text form_status_indication_error'>
                                            {isErrorText}
                                        </p>
                                    }
                                </div>
                                <button className="from_submit button_primary" type='submit'>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>
    )
}

export default Contact;
