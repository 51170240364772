import React, { useState } from 'react';
import "./gallerySlider.css";
import Slider from "react-slick";
import menu_close from '../../assets/icons/menu_close.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function GallerySlider({ sliderImages }) {

    const [isImageOpen, setIsImageOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 786,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    function closeImagePreview() {
        setIsImageOpen(false);
        setSelectedImage(null);
    }

    function openImagePreview(index) {
        // console.log(index)
        setSelectedImage(index);
        setIsImageOpen(true);
    }

    return (
        <>
            <Slider {...settings}>
                {
                    sliderImages.map((element, index) => {
                        return (
                            <div className='galleryslider_img_box' key={index} onClick={() => openImagePreview(index)} >
                                <div className="radial_gradiant"></div>
                                <div className="galleryslider_img" style={{ backgroundImage: `url(${element})` }}></div>
                            </div>
                        )
                    })
                }
            </Slider>
            {
                isImageOpen &&
                <div className="galleryslider_img_view">
                    <div className="blur"></div>
                    <div className="galleryslider_img_view_content">
                        <img className='galleryslider_img_close_icon' src={menu_close} alt="close" onClick={closeImagePreview} />
                        <div className="galleryslider_preview_img">
                            <img src={sliderImages[selectedImage]} alt="Image preview" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}


export default GallerySlider;
